.table-responsive {
    position: relative;
}

.table .value {
    font-size: 18px;
}

.table .value-check {
    color: #52c41a;
}

.table tr {
    cursor: pointer;
    transition: all ease .2s;
}

.table tr th:hover {
    background: rgba(0,0,0,.075);
}

.table th {
    border-top: none!important;
    font-weight: 400;
}

.table th label {
    max-width: 70%;
    margin: 0;
}

.table th, .table td {
    font-size: 16px;
    vertical-align: middle!important;
    line-height: 1.5;
}

.table th:not(:last-child), .table td:not(:last-child) {
    border-right: 1px solid #dee2e6;
}

.table td {
    font-weight: 300;
}

.react-bootstrap-table-page-btns-ul {
}

.pagination .page-item .page-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 32px;
    min-width: 32px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: none;
    border-radius: 4px;
    background-color: #fff;
    font-size: 16px;
}

.pagination .page-item:not(.active) .page-link:hover {
    background: #e9ecef;
}
.page-item.active .page-link {
    background-color: #038fde;
}


/*More info tip in tables*/
.table td .cell-name {
    display: flex;
    position: relative;
    color: #212529;
}
.more-info {
    display: none;
}

.table tr:hover .more-info {
    background: transparent;
    border-radius: 2px;
    color: #038fde;
    display: flex;
    font-size: 16px;
    line-height: 1;
    margin-bottom: 0;
    padding: 4px 5px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
}

.table tr:hover .cell-name {
    color: #038fde;
}
