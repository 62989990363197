.nav-tabs {
    font-size: 16px;
}

.nav-tabs .nav-link {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 1.3rem 1.5rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-right: 3px;
    transition: all ease .2s;
}

.nav-tabs .nav-link i {
    font-size: 22px;
    margin-right: 5px;
}

.nav-tabs .nav-item {
    transition: all ease .2s;
}

.nav-tabs .nav-link:hover {
    background: #fff;
    transition: all ease .2s;
}

.nav-tabs .nav-link:hover {
    color: #038fde!important;
}

.nav-tabs .nav-link.active {
    color: #038fde!important;
}

.custom-nav-tabs {
    border: none;
}

.custom-nav-tabs .nav-link {
    border-radius: 4px;
    background: #fff;
    border: none;
    margin-right: 7px;
    padding: 1rem 1.5rem;
    border: 1px solid transparent;
}

.custom-nav-tabs .nav-link.active {
    border: 1px solid #038fde;
}

.custom-nav-tabs:last-child .nav-link {
    margin: 0;
}


