@import "./tables.css";
@import "./forms.css";

.dt-brand {
    padding-right: 15px;
    padding-left: 15px;
}

.dt-brand__logo {
    margin: 0;
}

.dt-brand__logo-link {
    display: flex;
    align-items: center;
    font-size: 16px;
}

.dt-brand__logo-link i {
    font-size: 30px;
    margin-left: 5px;
}

.dt-header__toolbar {
    padding-right: 15px;
    padding-left: 15px;
}

.dt-nav__item {
    padding: 0 15px;
}

.dt-header__toolbar .dt-nav__link {
    font-size: 16px;
}

.dt-header__toolbar .dt-nav__link--active {
    color: #038fde;
}

.dt-header__toolbar .dt-nav__link>i {
    font-size: 18px;
    margin-right: 5px;
}

.dt-header__toolbar .dt-nav__item--logout {
    border-left: 1px solid #e8e8e8;
}

.service + .service-collapse {
    margin: 0 -200px;
}

.dt-footer {
    justify-content: center;
}

.cursor-pointer {
    cursor: pointer;
}

.btn-light {
    color: #212529 !important;
    background-color: #e5e5e5 !important;
    border-color: #e5e5e5 !important;
}

div[target='_collapseV'] {
    display: flex;
    flex: 1;
    overflow: hidden;
    background-color: maroon;

    max-height: 0;
    transition: max-height 1s ease-out;
}

div[target='_collapseH'] {
    display: flex;
    flex: 1;
    transition: max-width 1s ease;
}

/* Date picker */

.react-datepicker {
    display: flex !important;
}

.react-datepicker-wrapper {
    display: block !important;
}

.react-datepicker__header__dropdown {
    display: flex;
    justify-content: space-around;
    padding: 5px 0;
}

.react-datepicker__current-month {
    color: #262626 !important;
    font-family: "NoirPro", sans-serif;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.react-datepicker__day-name {
    color: #545454 !important;
    font-family: "NoirPro", sans-serif;
    font-size: 11px !important;
    padding: 5px 7px;
    width: auto !important;
}

.react-datepicker__day--disabled {
    color: #ccc !important;
    cursor: not-allowed !important;
}

.react-datepicker__day {
    font-size: 12px;
    padding: 5px 5px;
    width: 26px !important;
}

.react-datepicker__day,
.react-datepicker__month-select,
.react-datepicker__year-select {
    color: #262626;
    cursor: pointer;
    font-family: "NoirPro", sans-serif;
}

button.react-datepicker__navigation--previous:focus,
button.react-datepicker__navigation--next--with-time:focus {
    outline: none !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__time-list-item--selected
{
    background-color: #038fde !important;
    color: #fff !important;
}

.react-datepicker__time {
    border: none !important;
}

.react-datepicker__time-box {
    border-right: 1px solid #aeaeae !important;
}

.react-datepicker__year-select,
.react-datepicker__month-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url("https://i.ibb.co/b7xjLrB/selectbox-arrow.png") right center no-repeat;
    background-color: #fff;
    background-size: 25px;
    border-color: #ced4da;
    border-radius: .25rem;
    box-shadow: #bdbdbd;
    color: #444;
    font-size: 12px;
    display: block;
    height: auto;
    line-height: 16px;
    max-width: 100%;
    padding: 3px 40px 3px 10px;
}

.react-datepicker__year-select option,
.react-datepicker__month-select option {
    background-color: #fff;
}

/* for IE and Edge */
.react-datepicker__year-select::-ms-expand,
.react-datepicker__month-select::-ms-expand {
    display: none;
}

.react-datepicker__month-select:disabled::-ms-expand,
.react-datepicker__year-select:disabled::-ms-expand {
    background: #f60;
}

.react-datepicker__year-select:focus,
.react-datepicker__month-select:focus {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, .25);
    outline: none;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 95px !important;
}

.react-datepicker__header--time {
    padding: 8px 11px 8px 0 !important;
}

.react-datepicker__time-list {
    height: 230px !important;
}

.react-datepicker__month-dropdown-container--select {
    padding: 0 5px 0 10px;
}

.react-datepicker__year-dropdown-container--select {
    padding: 0 10px 0 5px;
}

.react-datepicker__month-wrapper {
    font-family: 'NoirPro', sans-serif;
    font-size: 13px;
}

.react-datepicker__month--selected {
    background-color: #038fde !important;
    color: #fff !important;
}

.react-datepicker__month-text {
    align-items: center !important;
    display: inline-flex !important;
    justify-content: center !important;
}

.react-datepicker__month .react-datepicker__month-text {
    cursor: pointer;
    height: 40px;
    width: 25%;
}

.react-datepicker__header.react-datepicker-year-header {
    padding-bottom: 5px;
}

